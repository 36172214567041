"use strict";

// Ensure ApexCharts is loaded
window.ApexCharts = require('apexcharts/dist/apexcharts.min.js');

var strokeColors = ['#3498DB', '#2ECC71', '#E74C3C', '#9B59B6', '#F1C40F'];

var series = []
var months = [];

// Class definition
var KTCardsWidgetChart = function () {
    var chart = null;
    var labelColor = KTUtil.getCssVariableValue('--kt-gray-500') || '#666';
    var borderColor = KTUtil.getCssVariableValue('--kt-gray-200') || '#ddd';
    var baseColor = KTUtil.getCssVariableValue('--kt-info') || '#1E90FF';

    var deepMerge = function (target, source) {
        // Simple deep merge implementation
        var output = Object.assign({}, target);
        if (typeof source !== 'object' || source === null) return source;

        Object.keys(source).forEach(key => {
            if (source[key] instanceof Object && key in output) {
                output[key] = deepMerge(output[key], source[key]);
            } else {
                output[key] = source[key];
            }
        });
        return output;
    };

    var getChartOptions = function (type, height) {
        var commonOptions = {
            series: series,
            chart: {
                fontFamily: 'inherit',
                type: type,
                height: height,
                toolbar: { show: false },
            },
            legend: {
                show: true,
                showForSingleSeries: true,
                position: 'top',
                horizontalAlign: 'center',
                fontSize: '12px',
                labels: { colors: labelColor },
                markers: {
                    width: 10,
                    height: 10,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    radius: 2
                },
                itemMargin: { horizontal: 10, vertical: 5 }
            },
            dataLabels: { enabled: false },
            xaxis: {
                categories: months,
                axisBorder: { show: false },
                axisTicks: { show: false },
                labels: { style: { colors: labelColor, fontSize: '12px' } },
                crosshairs: {
                    stroke: { color: baseColor, width: 1, dashArray: 3 }
                }
            },
            yaxis: {
                labels: {
                    style: { colors: labelColor, fontSize: '12px' },
                    formatter: function (val) {
                        if (typeof val === 'string') return val;
                        if (val === 0) return "no screenings";
                        if (val === 1) return "1 day";
                        return `${val} days`;
                    }
                }
            },
            tooltip: {
                style: { fontSize: '12px' },
                y: {
                    formatter: function (val) {
                        if (typeof val === 'string') return val;
                        if (val === 0) return "no screenings";
                        if (val === 1) return "< 1 day";
                        return `${val} days`;
                    }
                }
            },
            colors: strokeColors,
            grid: { borderColor: borderColor, strokeDashArray: 4 },
            noData: {
                text: 'No data yet',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: labelColor,
                    fontSize: '28px',
                    fontFamily: 'inherit'
                }
            }
        };

        if (type === 'area') {
            return deepMerge(commonOptions, {
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'white',
                        type: 'vertical',
                        shadeIntensity: 0.2,
                        inverseColors: false,
                        opacityFrom: 0.3,
                        opacityTo: 0.05,
                        stops: [10, 100]
                    }
                },
                stroke: {
                    width: 2,
                    colors: strokeColors
                },
                markers: { strokeColor: strokeColors, strokeWidth: 3 }
            });
        }

        if (type === 'bar') {
            return deepMerge(commonOptions, {
                plotOptions: {
                    bar: {
                        columnWidth: '65%',
                    }
                },
                stroke: { colors: ['transparent'] }
            });
        }

        return commonOptions;
    };
    var height = null;
    var initChart = function (type) {
        var element = document.getElementById('kt_apexcharts_3');
        if (!element) return;

        let jsonString = element.getAttribute('data-json');
        let jsonObject = JSON.parse(jsonString);
        series = jsonObject.series;
        months = jsonObject.months;
        // Get height from element's CSS
        if (height === null) {
            height = parseInt(KTUtil.css(element, 'height')) || 350;
        }

        if (chart) chart.destroy();

        chart = new ApexCharts(element, getChartOptions(type, height));
        chart.render();
    }

    return {
        init: function () {
            initChart('area');
            var toggleSwitch = document.getElementById('chart-toggle');
            if (toggleSwitch) {
                toggleSwitch.addEventListener('change', function () {
                    initChart(this.checked ? 'bar' : 'area');
                });
            }
        }
    }
}();

// Webpack support and DOM ready
if (typeof module !== 'undefined') module.exports = KTCardsWidgetChart;
KTUtil.onDOMContentLoaded(KTCardsWidgetChart.init);